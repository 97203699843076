<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 交易明细
 * @Date: 2020-12-24 21:29:47
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-24 21:32:07
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Transactiondetails.vue
-->
<template>
  <div>交易明细</div>
</template>

<script>
export default {}
</script>

<style>

</style>
